import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import './Home.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import hospital from './images/Depositphotos_103747962_XL-scaled.jpg';
import image1 from './images/WhatsApp Image 2024-03-28 at 7.06.56 PM.jpeg';
import image2 from './images/WhatsApp Image 2024-03-28 at 7.06.57 PM (1).jpeg';
import image3 from './images/WhatsApp Image 2024-03-28 at 7.06.57 PM.jpeg';
import image4 from './images/WhatsApp Image 2024-03-28 at 7.08.06 PM (1).jpeg';
import image5 from './images/WhatsApp Image 2024-03-29 at 4.46.04 PM (1).jpeg';
import image6 from './images/WhatsApp Image 2024-03-29 at 4.46.04 PM (2).jpeg';
import image7 from './images/WhatsApp Image 2024-03-29 at 4.46.04 PM.jpeg';
import image8 from './images/WhatsApp Image 2024-03-29 at 4.46.05 PM.jpeg';
import carouselimg from './images/carous.jpg';
import carouselimg1 from './images/carous2.jpg';

const Home = () => {
  const navigate = useNavigate();
  const [count, setCount] = useState(3);
  const [countdownFinished, setCountdownFinished] = useState(false);

  useEffect(() => {
    const galleryItems = document.querySelectorAll(".gallery-item");

    galleryItems.forEach(function(item) {
      item.addEventListener("animationend", function() {
        item.classList.add("hovered");
      });
    });
  }, []);

  return (
    <div>
       <div>
          <Carousel autoPlay={true} showThumbs={false} className="custom-carousel">
            <div className="carousel-slide">
              <div className="carousel-image-container">
                <img src={carouselimg} className="carousel-image" alt="Carousel Image" />
              </div>
            </div>
            <div className="carousel-slide">
              <div className="carousel-image-container">
                <img src={hospital} className="carousel-image" alt="Hospital Image" />
              </div>
            </div>
            <div className="carousel-slide">
              <div className="carousel-image-container">
                <img src={carouselimg1} className="carousel-image" alt="Carousel Image 1" />
              </div>
            </div>
          </Carousel>
          <h1 className='gallaryhead'>OUR GALLERY</h1>
          <div className="gallery">
            <div className="gallery-item" >
              <img src={image1} alt="Placeholder" />
            </div>
            <div className="gallery-item">
              <img src={image2} alt="Placeholder" />
            </div>
            <div className="gallery-item">
              <img src={image3} alt="Placeholder" />
            </div>
            <div className="gallery-item">
              <img src={image4} alt="Placeholder" />
            </div>
            <div className="gallery-item">
              <img src={image5} alt="Placeholder" />
            </div>
            <div className="gallery-item">
              <img src={image6} alt="Placeholder" />
            </div>
            <div className="gallery-item">
              <img src={image7} alt="Placeholder" />
            </div>
            <div className="gallery-item">
              <img src={image8} alt="Placeholder" />
            </div>
          </div>
        </div>
    </div>
  );
}

export default Home;
