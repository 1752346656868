import React from 'react';
import './App.css';
import AboutUs from './AboutUs';
import 'bootstrap/dist/css/bootstrap.min.css'
import Doctorprofile from './Doctorprofile';
import Enquiryform from './Enquiryform';
import Footer from './Footer';
import Home from './Home';
import Services from './Services';
import NavRouter from './NavRouter';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Clienttestimonial from './Clienttestimonial';
function App()   {
  return (
    <div className="App">
      <BrowserRouter>
        <NavRouter />
        <Routes>
          <Route exact path='/' element={<Home/>}/>
          <Route path="/service" element={<Services />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/drprofile" element={ <Doctorprofile/>} />
          <Route path="/eform" element={ <Enquiryform/>} />
          <Route path="/testimonial" element={ <Clienttestimonial/>} />
        </Routes>
        <Footer />
        
      </BrowserRouter>
    </div>
  );
}

export default App;
