import React from 'react';
import './Clienttestimonial.css'; // Import CSS file

const ClientTestimonial = () => {
  // Array of video URLs
  const videoUrls = [
    "https://youtu.be/JSgcTT6nFvo",
    "https://youtu.be/SCrmlgsRXqE",
    "https://youtu.be/oLIZ2RqknFk",
    "https://youtu.be/A3x1QtE-gV0"
  ];

  return (
    <div>
      <h2 className='testimonialh2'>Patients Speak</h2>
      <p className='testimonialp'>Hear the heartwarming stories of patients overcoming difficult afflictions</p>
      <div className="testimonial-videos">
        {videoUrls.map((videoUrl, index) => (
          <div key={index} className="testimonial-video">
            <iframe
              src={`https://www.youtube.com/embed/${getVideoId(videoUrl)}`}
              title={`Client Testimonial ${index + 1}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        ))}
      </div>
    </div>
  );
};

// Function to extract video ID from YouTube URL
const getVideoId = (url) => {
  const videoId = url.split('/').pop().split('?')[0].split('&')[0];
  return videoId;
};

export default ClientTestimonial;
