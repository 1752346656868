import React, { useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from './images/logo.png';
import './Navrouter.css';

const NavRouter = () => {
  const [expanded, setExpanded] = useState(false);

  const handleNavClose = () => setExpanded(false);

  return (
    <Navbar expand="lg" className="Navbar" variant="dark" expanded={expanded} onToggle={setExpanded}>
      <Container>
        <Navbar.Brand className='header'>
          <img src={logo} alt="Logo" className="logo-img" />
          <span className="hospital-name">Global Cancer Hospital And Research Center</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link onClick={handleNavClose}>
              <Link className='routes' to="/">Home</Link>
            </Nav.Link>
            <Nav.Link onClick={handleNavClose}>
              <Link className='routes' to="/service">Services</Link>
            </Nav.Link>
            <Nav.Link onClick={handleNavClose}>
              <Link className='routes' to='/aboutus'>About Us</Link>
            </Nav.Link>
            <Nav.Link onClick={handleNavClose}>
              <Link className='routes' to='/drprofile'>Doctor</Link>
            </Nav.Link>
            <Nav.Link onClick={handleNavClose}>
              <Link className='routes' to='/eform'>Enquiry Form</Link>
            </Nav.Link>
            <Nav.Link onClick={handleNavClose}>
              <Link className='routes' to='/testimonial'>Testimonial</Link>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavRouter;
