import React from 'react';
import './Services.css';
import MultiCarousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import hospital from './images/cancer-care-center-in-jaipur.jpg';
import { Button, Card, Container } from 'react-bootstrap';
import lungcancer from './images/lung cancer.jpg';
import hematologicalcancer from './images/hemaonco.jpg';
import oncology from './images/oncology.webp';
import breastcancer from './images/breastcancer.jpg';
import { useNavigate } from 'react-router-dom';

const Services = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className='serviceshead'>OUR SERVICES</div>
      <Container>
        <MultiCarousel
          additionalTransfrom={0}
          arrows
          autoPlay
          autoPlaySpeed={2000}
          centerMode={false}
          containerClass="container-with-dots"
          draggable
          focusOnSelect={false}
          infinite
          keyBoardControl
          minimumTouchDrag={80}
          responsive={{
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: 3,
              partialVisibilityGutter: 40,
            },
            tablet: {
              breakpoint: { max: 1024, min: 464 },
              items: 2,
              partialVisibilityGutter: 30,
            },
            mobile: {
              breakpoint: { max:464, min: 0 },
              items: 1,
              partialVisibilityGutter: 30,
             
            },
          }}
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          <Card className='scard'>
            <Card.Img className='img' src={oncology} />
            <Card.Body>
              <Card.Title><h4 className='service-title'>Oncology</h4></Card.Title>
              <Card.Text>
                <h6 className='service-info'>Comprehensive cancer care services including
                   diagnosis, treatment, and follow-up care for various types of 
                   cancers such as leukemia, lymphoma, and solid tumors.</h6>
              </Card.Text>
              <Button className='sbutton' onClick={() => navigate('/eform')}>Contact Us</Button>
            </Card.Body>
          </Card>

          <Card className='scard'>
            <Card.Img className='img' src={hospital} />
            <Card.Body>
              <Card.Title><h4 className='service-title'>Consultation</h4></Card.Title>
              <Card.Text>
                <h6 className='service-info'> Initial consultation with oncologist for 
                  evaluation, discussion of treatment options, and personalized care plan development.</h6>               
              </Card.Text>
              <Button className='sbutton' onClick={() => navigate('/eform')}>Contact Us</Button>
            </Card.Body>
          </Card>

          <Card className='scard'>
            <Card.Img className='img' src={lungcancer} />
            <Card.Body>
              <Card.Title><h4 className='service-title'>Lung Cancer</h4></Card.Title>
              <Card.Text>
                <h6 className='service-info'>Specialized treatment modalities including surgery, chemotherapy, radiation therapy,
                   and targeted therapy for lung cancer patients, along with supportive care services.</h6>               
              </Card.Text>
              <Button className='sbutton' onClick={() => navigate('/eform')}>Contact Us</Button>
            </Card.Body>
          </Card>

          <Card className='scard'>
            <Card.Img className='img' src={breastcancer} />
            <Card.Body>
              <Card.Title><h4 className='service-title'>Breast cancer</h4></Card.Title>
              <Card.Text>
                <h6 className='service-info'>Advanced therapies such as hormone therapy, immunotherapy, and breast-conserving surgeries for breast
                   cancer patients, alongside genetic testing and counseling services.</h6>           
              </Card.Text>
              <Button className='sbutton' onClick={() => navigate('/eform')}>Contact Us</Button>
            </Card.Body>
          </Card>

          <Card className='scard'>
            <Card.Img className='img' src={hematologicalcancer} />
            <Card.Body>
              <Card.Title><h4 className='service-title'>Hematological Oncology</h4></Card.Title>
              <Card.Text>
                <h6 className='service-info'>Specialized consultation with hematologist-oncologist for patients with blood cancers. Includes comprehensive evaluation, discussion of treatment options including chemotherapy, immunotherapy, stem cell transplant,
                   and targeted therapies, as well as management of associated symptoms and supportive care.</h6>               
              </Card.Text>
              <Button className='sbutton' onClick={() => navigate('/eform')}>Contact Us</Button>
            </Card.Body>
          </Card>
        </MultiCarousel>
      </Container>
      
    </div>
  );
}

export default Services;
