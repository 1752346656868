import React, { useEffect, useRef } from 'react';
import { FaMapMarkerAlt, FaGraduationCap, FaPhoneAlt, FaEnvelope } from 'react-icons/fa';
import './Doctorprofile.css'; // Import CSS file for styling
import doctor from './images/doctorphoto.jpeg';
import MultiCarousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Card, Button, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Dlogo from './images/dinfologo.jpg';

const doctors = [
  { name: "DR. NITIN L. CHAUDHARI", qualification: " M.B.B.S,MD,DM(medical oncology)", specialty: "Visiting Medical Oncologist & Hematologist" },
  { name: "DR. SWAPNIL S. CHAUDHARI  ", qualification: "M.B.B.S,MD(Pulmonary Medicine)", specialty: "Visiting Chest Physician & Intensivisit" },
  { name: "DR. CHETAN B. CHAUDHARI ", qualification: "M.B.B.S,MD(Pulmonary Medicine)", specialty: "Visiting Chest Physician & Intensivisit" },
  { name: "DR. DIPAK PATIL ", qualification: "M.B.B.S,MD(Pulmonary Medicine)", specialty: "Visiting Chest Physician & Intensivisit" },
  { name: "DR. PRASHANT SOMANI", qualification: "M.B.B.S,MD(Pulmonary Medicine),DM(Endocrinology)", specialty: "Visiting Physician & Endocrinologist" },
  { name: "DR. HARSHAD MAHAJAN ", qualification: "M.B.B.S,MD(Anesthesiology)", specialty: "Visiting Anesthesiologist" },
  { name: "DR. NILESH PATIL ", qualification: "M.B.B.S,MS,M.C.H(Plastic Surgery)", specialty: "Visiting Plastic & Reconstructive Surgeon" },
  { name: "DR. YOGESH R. JAISWAL ", qualification: "M.B.B.S,MS,M.C.H(Plastic Surgery)", specialty: "Visiting Plastic & Reconstructive Surgeon" },
];

const Doctorprofile = ({ google }) => {
  const navigate = useNavigate();
  

  return (
    <div>
      <div className="doctor-profile-container">
        <div className="doctor-photo-card">
          <img src={doctor} alt="Doctor" />
          <div className="doctor-name">
            <h2 className='doctor-nameh2'>Dr. Atul Bharambe</h2>
            <p className='doctor-namep'>Consultant - Oncosurgeon & Laproscopic Surgeon</p>
          </div>
        </div>
        <div className="doctor-info-card">
          <h1 className='doctor-info-cardh1'>Dr. Atul Bharambe</h1>
          <p className='doctor-info-cardp'>Consultant - Oncosurgeon & Laproscopic Surgeon</p>
          <div className="doctor-info">
            <div className="info-item">
              <p className="info-itemp"><FaGraduationCap className='icon'/><strong className='infotitle'></strong> 
              <span className='info-itempspan '>M.B.B.S,D.N.B(Gen.Surgery), Dr.N.B(Surgical Oncology), F.M.A.S.</span></p>
            </div>
            <div className="info-item">
              <p className="info-itemp"><FaPhoneAlt  className='icon' /><strong className='infotitle'></strong>
              <span  className='info-itempspan '>+918850939828</span></p>
            </div>
            <div className="info-item">
              <p className="info-itemp"><FaEnvelope  className='icon' /><strong className='infotitle'></strong>
              <span  className='info-itempspan '>globalcancerhospital22@gmail.com</span></p>
            </div>
            <div className="info-item">
              <p className="info-itemp"><FaMapMarkerAlt className='icon' /><strong className='infotitle'></strong>
              <span  className='info-itempspan '> Plot No. 13, Vivekanand Nagar, Rupali Hotel, Jalgaon City, Jalgaon - 425001</span></p>
            </div>
            <div >
              <Button href='/eform' className='doctor-button'>Contact Us</Button>
            </div>
          </div>
        </div>
      </div>


      <div>
        <div className='visitordoctorhead'>Our Visitor Doctors</div>

        <Container className="dcontainer">
          <MultiCarousel
            additionalTransfrom={0}
            arrows
            autoPlay
            autoPlaySpeed={2000}
            centerMode={false}
            containerClass="container-with-dots"
            draggable
            focusOnSelect={false}
            infinite
            keyBoardControl
            minimumTouchDrag={80}
            responsive={{
              desktop: {
                breakpoint: { max: 4000, min: 1024 },
                items: 3,
                partialVisibilityGutter: 40,
              },
              tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 2,
                partialVisibilityGutter: 30,
              },
              mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1,
                partialVisibilityGutter: 30,
              },
            }}
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
          >
            {doctors.map((doctor, index) => (
              <div className="dcard-container" key={index} >
                <Card className="dcard">
                  <Card.Body className="dcard-body">
                    <div className="logo-container">
                      <img src={Dlogo} alt="Logo" className="dinfologo" /> {/* Add logo */}
                    </div>
                    <Card.Title className="dcard-title">{doctor.name}</Card.Title>
                    <Card.Subtitle className="dcard-subtitle">{doctor.qualification}</Card.Subtitle>
                    <Card.Text className="dcard-text">
                      <span><strong>Specialty:</strong> {doctor.specialty}</span>
                    </Card.Text>
                    <Button className="dbutton" onClick={() => navigate('/eform')}>Take Appointment</Button>
                  </Card.Body>
                </Card>
              </div>
            ))}
          </MultiCarousel>
        </Container>
      </div>
    </div>
  );
}

export default Doctorprofile;
