// EnquiryForm.js
import React, { useReducer, useRef } from 'react';
import './Enquiryform.css';
import emailjs from '@emailjs/browser';

const EnquiryForm = () => {
  const form =useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_6302kbd', 'template_i9vvgk7', form.current, '2CsqJ4WB-GGnQ6v62')
      .then((result) => {
        console.log(result.text);
        alert("Send mail Successfully")
      }, (error) => {
        console.log(error.text);
      });
    e.target.reset()
  };
   return (
   <div>
     <div className='econtainer'>
      <div className="form-container">
      <h2 className='fhead'>Enquire Now</h2>
      <form ref={form} onSubmit={sendEmail}>
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" name="user_name" placeholder="Your name.." required />
        </div>

        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input type="email" id="email" name="user_email" placeholder="Your email.." required />
        </div>

        <div className="form-group">
          <label htmlFor="phone">Phone:</label>
          <input type="tel" id="phone" name="user_number" placeholder="Your phone.." />
        </div>

        <div className="form-group">
          <label htmlFor="subject">Subject:</label>
          <input type="text" id="subject" name="subject" placeholder="Your subject.." />
        </div>
        <div className="form-group">
          <label htmlFor="subject">message</label>
          <textarea name='message'row={3}></textarea>
        </div>

        <div >
          <button className="ebt-container" type="submit">Submit</button>
        </div>
      </form>
    </div>   
    </div>
    <div className="map-container">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3724.735598613366!2d75.56260567476852!3d21.003233188655813!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd90f13b5231c01%3A0x4b31fc8a97369277!2sGlobal%20Cancer%20Hospital%20And%20Research%20Centre!5e0!3m2!1sen!2sin!4v1711640485968!5m2!1sen!2sin" 
    width="100%"
     height="450"
     allowfullscreen="" 
     loading="lazy" 
     
     referrerpolicy="no-referrer-when-downgrade">

    </iframe>
      </div>
   </div>
  );
}

export default EnquiryForm;
