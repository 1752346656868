// Footer.js
import React from 'react';
import './Footer.css';
import { FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import logo from './images/logo.png';
import { MdPhoneInTalk } from "react-icons/md";
import { BsFacebook, BsInstagram, BsTwitter } from "react-icons/bs";
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="logo-section">
        <div className="logo-contain">
          <img src={logo} alt="Hospital Logo" className="logo" />
          <h1 className='logo-containh1'>Global Cancer Hospital And Research Center</h1>
        </div>
      </div>

      <div className="footer-content">
        <div className="footer-sectionabout">
          <h2>About Us</h2>
          <p className='About'>
            Our mission is to offer comprehensive, compassionate, and personalized care to every patient, utilizing the latest advancements in medical technology and treatment protocols.
          </p>  
        </div>
        
        <div className="footer-sectioncontact">
          <h2>Contact Information</h2>
          <p>
            <a className='addinfo' href='https://www.google.com/maps/place/Global+Cancer+Hospital+And+Research+Centre/@21.0032282,75.5626057,17z/data=!3m1!4b1!4m6!3m5!1s0x3bd90f13b5231c01:0x4b31fc8a97369277!8m2!3d21.0032282!4d75.5651806!16s%2Fg%2F11vhcxphxk?authuser=0&entry=ttu'>
              <FaMapMarkerAlt/> : Plot No.13, Vivekanand Nagar, Behind Rupali Hotel, Jalgaon City, Jalgaon - 425001 (Swatantra Chowk)
            </a>
          </p>
          <p><FaEnvelope/> : globalcancerhospital22@gmail.com</p>
          <p><MdPhoneInTalk /> :+918850939828</p>
        </div>

        <div className='footer-sectionlinks'>
          <h2>Quick Links</h2>
          <Link to='/' className='link'><p>Home</p></Link>
          <Link to='/service' className='link'><p>Services</p></Link>
          <Link to='/eform' className='link'><p>Enquire Us</p></Link>
          <Link to='/drprofile' className='link'><p>Doctor</p></Link>
          <Link to='/aboutus' className='link'><p>About us</p></Link>
        </div>

        <div className='footer-sectionfollow'>
          <h2>Follow Us</h2>
          <a className='mediaicons' href='https://www.facebook.com/p/Dr-Atul-Bharambes-Cancer-Care-100075991989694/'><BsFacebook /></a>
          <a className='mediaicons' href='#'><BsInstagram /></a>
          <a className='mediaicons' href='#'><BsTwitter /></a>
        </div>
      </div>

      <div className="copyright-section">
        <p>&copy; {new Date().getFullYear()} <span className='chospital'>Global Cancer Hospital And Research Center</span>. All Rights Reserved By <a className='marketingagency' href='https://wa.link/53j56t'>Shree Digital Marketing Agency & Technoweit</a>.</p>
      </div>
    </footer>
  );
}

export default Footer;
