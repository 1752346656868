import React from 'react';
import './AboutUs.css';
import doctorPhoto from './images/doctorphoto.jpeg';
import { FaHospital, FaUser, FaClock, FaHome } from "react-icons/fa";

const AboutUs = () => {
  return (
    <div className="about-container">
      <div className="about-heading">
        <h2 className="about-containerhead">About Us</h2>
      </div>
      <div className="about-content">
        <div className="about-column1">
          <img src={doctorPhoto} alt="Doctor" className="doctor-photo" />
        </div>
        <div className="about-column2">
          <p className='about-containerpara'>
            Leading Cancer Specialist Dr. Atul Bharambe Strongly Believes that cancer can be cured
          </p>
          <p className='about-containerpara'>
            Dr. Atul Bharambe provides free services to NIMS at Nandurbar and DUPMC at Jalgaon
          </p>
          <p className='about-containerpara'>
            He has performed surgeries free of cost for more than 1500+ Patients to date under various schemes.
          </p>
          <p className='about-containerpara'>
            National Anmol Ratna Award By Bakvikas Foundation in 2022
          </p>
          <p className='about-containerpara'>
            aarogyadut sanman puraskar
          </p>
        </div>
      </div>
      <div className="services-container">
        <h1 className="main-heading">WHY CHOOSE GLOBAL CANCER CENTER?</h1>
        <div className="row">
          <div className="service-card">
            <div className="Aicon"><FaHospital /></div>
            <div>
              <h3 className="service-cardhead">COMPREHENSIVE CANCER CARE UNDER ONE ROOF</h3>
              <p className="service-cardpara">To facilitate a seamless treatment journey for our patients, we have made all major diagnostic and treatment facilities available in one place.</p>
            </div>
          </div>
          <div className="service-card">
            <div className="Aicon"><FaUser /></div>
            <div>
              <h3 className="service-cardhead">PERSONALIZED CANCER CARE</h3>
              <p className="service-cardpara">Our multidisciplinary care team devises customized treatment plans for different types of cancer and hematological disorders; this way, we ensure that every patient receives the right care from the beginning.</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="service-card">
            <div className="Aicon"><FaClock /></div>
            <div>
              <h3 className="service-cardhead">NO DELAYS IN YOUR TREATMENT</h3>
              <p className="service-cardpara">At HCG Manavata Cancer Hospital, every patient is a priority, and there will be no unnecessary waiting or delays in treatment.</p>
            </div>
          </div>
          <div className="service-card">
            <div className="Aicon"><FaHome /></div>
            <div>
              <h3 className="service-cardhead">CARE CLOSER TO HOME</h3>
              <p className="service-cardpara">Patients do not have to travel to far-off cities for tests or treatments—we bring the best in cancer care to you.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
